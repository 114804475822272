/* General */
$navbar_height: 4.5rem;
$content_section_spacing: 3rem;

html {
    scroll-behavior: smooth;
}

body {
    // Keep body content below navbar
    padding-top: $navbar_height;
}

body p {
    color: $gray-800;
}

a:link, a:visited {
    text-decoration: none;
}

section.content-section {
    padding-top: $content_section_spacing;
    padding-bottom: $content_section_spacing;
}

.font-size-small {
    font-size: $font-size-base * 0.8;
}

.rotate-45 {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.rotate-315 {
    -webkit-transform: rotate(315deg);
    -moz-transform: rotate(315deg);
    -ms-transform: rotate(315deg);
    -o-transform: rotate(315deg);
    transform: rotate(315deg);
}

/* Links */
nav a {
    transition-property: color, background-color;
    transition-duration: .15s;
    transition-timing-function: ease;
}

nav.nav-pills a.active {
    background-color: #3e4348 !important;
}

a.iconlink {
    text-decoration: none !important;
}

a.navtarget, a.navtarget:target {
    display: block;
    visibility: hidden;
    position: relative;
    top: -($navbar_height + 1rem);
}

.navbar-scrolled-down {
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
}

.navbar-scrolled-up {
    transform: translateY(0);
    transition: transform 0.3s ease-in-out;
}

/* Backgrounds */
.bg-alt {
    background: $gray-200;
}

div.background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    background: url("/assets/images/background.jpg") #161616 no-repeat;
    background-size: cover;
}

div.background:after {
    content: '';
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    // Dotted pattern
    background: rgba(0, 0, 0, 0.6);
    background-image: radial-gradient(#161616 33%, transparent 33%);
    background-size: 3px 3px;
}

div.background-separator-box {
    background: rgba(127, 127, 127, 0.1);
    border-radius: 12px;
}

div.header-box {
    width: fit-content;
}

/* Work */
img.work-client {
    filter: grayscale(0.3);

    transition-property: filter, transform;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

img.work-client:hover {
    filter: none;
    transform: scale(1.05);
}

/* Projects */
.project-card {
    .card-img-top {
        filter: brightness(90%);
    }
}


/* Publications */
div.pubcategory {
    text-align: left;
    font-weight: $font-weight-light;
    font-size: $font-size-base * 1.75;
    color: $gray-800;

    padding-left: 1.5rem;
    margin-bottom: 0.5rem;
}

.publication-card {
    transition-property: box-shadow;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;

    box-shadow: 0 0 0.4rem rgba($black, 0.10);
}

.publication-card:hover {
    box-shadow: 0 0 0.85rem rgba($black, 0.35);
}

.publication-card .publication-abstract-preview {
    a {
        color: $gray-800;
        text-decoration: none;

    }
}

pre.cite-data {
    overflow: scroll;

    font-size: $font-size-base * 0.8;
    max-height: 8em;

    padding: 0.4em;
    background: rgba($gray-100, 0.5);

    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
}

div.cite-action-icons {
    height: fit-content;
    padding: 0.3em;

    text-align: center;

    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
}

@keyframes animation-fadeOutIn {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.animation-fadeOutIn {
    animation-name: animation-fadeOutIn;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
}

/* About */
.about-row {
    display: flex;
    align-items: center;

    max-height: 75px;
}

.about-event {
    flex-grow: 0;

    display: flex;
    width: 75px;
    height: 75px;

    font-size: 180%;
    color: $gray-600;

    vertical-align: center;
    justify-content: center;
    align-items: center;

    background-color: $gray-200;
    border: 5px solid $gray-300;
    border-radius: 50%;

    -webkit-transition: all .25s ease-in-out;
    -moz-transition: all .25s ease-in-out;
    -ms-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
}

.about-event:hover {
    -webkit-box-shadow: 0 0 1rem rgba($black, .35);
    -moz-box-shadow: 0 0 1rem rgba($black, .35);
    box-shadow: 0 0 1rem rgba($black, .35);
}

.about-event.active {
    // Not distinguishable right now
}

.about-event-left {
    flex-grow: 1;
    flex-basis: 0 !important;

    text-align: right;
    margin-right: $spacer;
}

.about-event-right {
    flex-grow: 1;
    flex-basis: 0 !important;

    text-align: left;
    margin-left: $spacer;
}

.about-spacer {
    height: 56px;
    width: 0;
    margin: 0 auto;

    border-left: 5px solid $gray-300;
}

.about-spacer-icon {
    flex-grow: 0;
    display: flex;

    font-size: 180%;
    color: $gray-600;

    margin: 8px 0;
}

/* Contact */
#contactRecaptcha.is-invalid > div {
    border: 1px solid $danger;
    border-radius: $border-radius;
}

#contactRecaptcha.is-invalid ~ .invalid-feedback {
    display: initial !important;
}

/* Policy texts */
.policy-text {
    text-align: left;

    h1 {
        font-size: 1.75em;
    }

    h2 {
        font-size: 1.25em;
    }

    h3 {
        font-size: 1.1em;
    }
}
